var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "baidu-map",
        {
          staticClass: "bm-view",
          staticStyle: { width: "calc(100% - 40px)", height: "90vh" },
          attrs: {
            zoom: 15,
            center: _vm.center,
            "inertial-dragging": "",
            "scroll-wheel-zoom": true,
          },
          on: { ready: _vm.mapReady },
        },
        [
          _c("bm-geolocation", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              showAddressBar: true,
              autoLocation: true,
            },
          }),
          _c("bm-local-search", {
            staticClass: "search",
            attrs: { keyword: _vm.keyword, "auto-viewport": true },
            on: { searchcomplete: _vm.search },
          }),
          _c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }