<template>
	<div>
		<baidu-map
        class="bm-view"
        :zoom="15"
        :center="center"
        inertial-dragging
        @ready="mapReady"
        :scroll-wheel-zoom="true"
				style="width: calc(100% - 40px); height: 90vh;"
      >
        <!-- 定位控件   anchor="BMAP_ANCHOR_BOTTOM_RIGHT"代表放在右下角 -->
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :showAddressBar="true"
          :autoLocation="true"
        ></bm-geolocation>
        <!-- 地区检索  keyword：关键字搜索   @searchcomplete：检索完成后的回调函数   auto-viewport：检索结束后是否自动调整地图事业  -->
        <bm-local-search
          :keyword="keyword"
          @searchcomplete="search"
          :auto-viewport="true"
          class="search"
        ></bm-local-search>
        <!-- 缩放控件   anchor代表控件停靠位置   anchor="BMAP_ANCHOR_TOP_RIGHT"代表放在右上角-->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      </baidu-map>
	</div>
</template>
<script>
import Vue from "vue";
import BaiduMap from "vue-baidu-map";

Vue.use(BaiduMap, {
	ak: "D498803bbfd0ae46ae2f736f9ee5cdcf"
});

export default {

}
</script>
<style lang="scss" scoped>
.bm-view {
	overflow: auto;
	margin: auto;
	margin-top: 15px;
}
</style>y